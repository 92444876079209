import React,{useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import Info from './info';
import './App.css';
import HeaderPage from './Components/Header/Header';
import Paperviewer from './Pages/Home/Paperviewer';
import Homepage from './Pages/Home/Home';

function App() {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }

    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [])

  return (
    <section>
      <div>
        <HeaderPage />
      </div>

      <div>
        <Routes>
          <Route path='/' index element={<Homepage />} />
          <Route path='/Info/:id' element={<Info />} />
          <Route path='/Home' element={<Homepage />} />
          <Route path='/paperview/:id' element={<Paperviewer />} />
        </Routes>
      </div>
    </section>
  );
};

export default App;