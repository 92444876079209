import React from 'react';
// import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Logo/TELANGANANIJAMLOGO.png';

const HeaderPage = () => {

    const navigate = useNavigate();

    const getnavigate = () => {
        navigate('/home');
     }

    return(
        <section className='position-fixed w-100 z-index-999'>
            <div className='text-center py-2 bg_color_bule'>
                <img className='cursor_pointer' alt='logo' src={logo} width={250} height={65} onClick={() => {getnavigate()}}/>
                {/* <h1 className='cursor_pointer' onClick={() => {getnavigate()}}>Telangana Nijam</h1> */}
            </div>
        </section>
    )
}

export default HeaderPage;