import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import JsonData from '../../dataMain.json';
import { useNavigate } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import { GLOBAL_DOMAIN, GLOBAL_VERSION, GLOBAL_IMAGES, GLOBAL_CONTAINER } from '../../Config/Config';
import axios from 'axios';
import Placeholder from 'react-bootstrap/Placeholder';

const Homepage = () => {

    const navigate = useNavigate();
    const multipleNewspapperData = JsonData.Multiplenewspapper;
    const [selectedNewspaperData, setselectedNewspaperData] = useState({});
    const [date, setdate] = useState();
    const [timezone, settimezone] = useState();
    const [getnews, setnews] = useState();
    const [States, setStates] = useState([]);
    const [selectedstateId, setselectedstateId] = useState();
    const [selecteddistrictsId, setselecteddistrictsId] = useState();
    const [Districts, setDistricts] = useState();
    const [selectedstate, setselectedstate] = useState('');
    const [selecteddistricts, setselecteddistricts] = useState('');
    const [demosate, setdemostate] = useState("TELANGANA");

    useEffect(() => {
        let today = new Date();
        setdate(formatDate(today));
        settimezone((((new Date().toString()).match(/\(([^\)]+)\)$/)[1]).match(/\b(\w)/g)).join(''));
        filterdata(formatDate(today));
        GETSTATES();
    }, []);

    useEffect(() => {
        GETNews();
    }, [date, selectedstateId, selecteddistrictsId]);

    const GETNews = () => {
        axios({
            method: 'get',
            // headers: Api_Headers,
            url: GLOBAL_DOMAIN + GLOBAL_VERSION + 'news?',
            params: {
                type: "list",
                limit: 7,
                date : date,
                stateId: selectedstateId,
                districtId: selecteddistrictsId,
                timezone: timezone
            }
        }).then((res) => {
            // console.log(res.data?.rows);
            setnews(res?.data?.rows);
            // setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    const GETSTATES = () => {
        axios({
            method: 'get',
            // headers: Api_Headers,
            url: GLOBAL_DOMAIN + GLOBAL_VERSION + 'lookupcodes?' + 'type=drop&limit=100&lookupType=STATE_NAME',
        }).then((res) => {
            // console.log(res);
            setStates(res?.data);
            setselectedstate(res?.data[0]);
            setselectedstateId(res?.data[0].id);
            GETDISTRICTS(res?.data[0].id);
            // setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    const GETDISTRICTS = (event) => {
        const getlookiupname = States.find(dataitems => dataitems.id === parseInt(event));
        if (getlookiupname != undefined) {
            setdemostate('');
        }
        axios({
            method: 'get',
            // headers: Api_Headers,
            url: GLOBAL_DOMAIN + GLOBAL_VERSION + 'lookupcodes?' + 'type=drop&limit=100&lookupType=' + demosate,
        }).then((res) => {
            console.log(res);
            setDistricts(res.data);
            if (selecteddistrictsId == '' || selecteddistrictsId == null) {
                setselecteddistrictsId(res.data[0].id)
            }
            // setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    const filterdata = useCallback((date) => {
        const filterpaper = multipleNewspapperData?.filter((item) => item.date === date)
        setselectedNewspaperData(filterpaper);
    }, [])

    const formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    const getnavigate = (event) => {
        console.log(event)
        navigate('/paperview/' + event.id, { state: { PaperData: event } });
    }

    const dateaction = (event) => {
        filterdata(formatDate(event.target.value))
        setdate(event.target.value)
        console.log(event.target.value)
    }

    const disabledDates = [formatDate(new Date("2024-01-13")), formatDate(new Date("2024-01-14"))]

    return (
        <section>
            <div className='position-fixed w-100 mt-80 z-index-999'>
                <div className='card px-5'>
                    <div className='d-flex align-items-center'>
                        <div style={{ width: '200px' }}>
                            <Form.Control type="date" disabled={true} value={date} onChange={(e) => dateaction(e)} max={formatDate(new Date())} datesDisabled={disabledDates} />
                        </div>
                        <div style={{ width: '200px' }} className='mx-2'>
                            <Form.Select aria-label="Default select example"
                                onChange={e => {
                                    GETDISTRICTS(e.target.value); setselectedstateId(e.target.value)
                                }}
                                value={selectedstateId}
                            >
                                <option value={0}>Select State</option>
                                {States && States.map((state, index) => {
                                    return (
                                        <option key={index} value={state.id}>{state.lookup_display_name}</option>
                                    )
                                })}
                            </Form.Select>
                            {/* <select id="inputState" class="form-select"
                            onChange={e => {
                                GETDISTRICTS(e.target.value); setselectedstate(e.target.value); console.log(e.target.value)
                            }}>
                                <option selected>Select State</option>
                                {States && States.map((state, index) => {
                                    return (
                                        <option key={index} value={JSON.stringify(state)}>{state.lookup_display_name}</option>
                                    )
                                })}
                            </select> */}
                        </div>
                        <div style={{ width: '200px' }} className='mx-2'>
                            <Form.Select aria-label="Default select example" value={selecteddistrictsId}
                                onChange={e => { setselecteddistrictsId(e.target.value) }}>
                                {/* <option>Select Districts</option> */}
                                {Districts && Districts.map((districts, index) => {
                                    return (
                                        <option key={index} value={districts.id}>{districts.lookup_display_name}</option>
                                    )
                                })}
                            </Form.Select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-120'>
                {/* <div className='mb-3'>
                    <h4>{date} News Paper</h4>
                </div> */}
                {getnews && getnews.length > 0 ?
                    <Row>
                        {getnews?.map((items, index) => {
                            return (
                                <Col xs={12} sm={6} md={3} key={index} className='my-2'>
                                    <div className='w-100 cursor_pointer' onClick={() => { getnavigate(items) }}>
                                        <img src={GLOBAL_IMAGES + GLOBAL_CONTAINER + '/' + items.images[0].image_path} alt='paper' className='w-100' style={{ height: 'auto' }} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                                    </div>
                                    <div className='text-center my-2'>
                                        <p>{items?.date}</p>
                                    </div>
                                </Col>
                            )
                        })
                        }
                    </Row>
                    :
                    <Row className='container'>
                         <Col xs={12} sm={6} md={3} className='my-2'>
                        <Placeholder as="p" animation="glow">
                            <Placeholder xs={12} style={{ height: '40vh' }} />
                        </Placeholder>
                        </Col>
                        <Col xs={12} sm={6} md={3} className='my-2'>
                        <Placeholder as="p" animation="glow">
                            <Placeholder xs={12} style={{ height: '40vh' }} />
                        </Placeholder>
                        </Col>
                        <Col xs={12} sm={6} md={3} className='my-2'>
                        <Placeholder as="p" animation="glow">
                            <Placeholder xs={12} style={{ height: '40vh' }} />
                        </Placeholder>
                        </Col>
                    </Row>
                }
            </div>
        </section>
    )
}

export default Homepage